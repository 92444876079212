.globalPageTitle {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    font-size: 60px;
}

.footerStyle {
   color: white;
    text-align: center;
    width:100%;
    height: auto; 
    background-color: #242424;
    padding: 5px;
    position: absolute;
    z-index: 10;
}

 #page-container {
    position: relative;
    min-height: 100vh;
    background-color: #242424;
    color: white;
  }
  
  #content-wrap {
    padding-bottom: 3rem;    /* Footer height */
  }
  
  #footer {
    /* margin: 40px; */
    /* padding: 40px; */
    text-align: center;
    font-size: 15px;

    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3rem;            /* Footer height */
  }


 .center {
  display: block;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.penguinContainer {
    border: 2px solid #F2536E;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

   .lightyearContainer {
    border: 2px solid #1D96FA;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

  .ovContainer {
      border: 2px solid #ef0000;
      padding: 10px;
      border-radius: 15px;
      /* margin: 6px; */
    }

  .siteContainer {
    border: 2px solid #007E86;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

  .jsContainer {
    border: 2px solid #F7DF1E;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

  .reactContainer {
    border: 2px solid #53C1DE;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

    .rnContainer {
    border: 2px solid #61DAFB;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

.dfContainer {
    border: 2px solid #EF6C00;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

  .ipContainer {
    border: 2px solid #7EFFE0;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

  .graContainer {
    border: 2px solid #c82357;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

  .fdContainer {
    border: 2px solid #73fcfc;
    padding: 10px;
    border-radius: 15px;
    /* margin: 6px; */
  }

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: underline;
  color: white;
}

a:active {
  text-decoration: underline;
  color: white;
}

.linkHover {
  border-radius: 10px;
}

.linkHover:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}